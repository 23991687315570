import { utils, write } from "xlsx";
import { saveAs } from "file-saver";

// 导出为 excel
export default function (list, name) {
    const sheet = utils.json_to_sheet(list);
    const blob = sheet2blob(sheet);
    saveAs(blob, `${name}.xlsx`);
}

export function downloadByHtml(dom, name) {
    const sheet = utils.table_to_sheet(dom)
    const blob = sheet2blob(sheet);
    saveAs(blob, `${name}.xlsx`);
}

// 将一个sheet转成最终的excel文件的blob对象，然后利用URL.createObjectURL下载
function sheet2blob(sheet, sheetName = "表格1") {
    var workbook = {
        SheetNames: [sheetName],
        Sheets: {},
    };
    workbook.Sheets[sheetName] = sheet; // 生成excel的配置项

    var wopts = {
        bookType: "xlsx", // 要生成的文件类型
        bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
        type: "binary",
    };
    var wbout = write(workbook, wopts);
    var blob = new Blob([save2Buffer(wbout)], {
        type: "application/octet-stream",
    });

    return blob;
}

function save2Buffer(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
}





